import { AUTHENTICATION } from "../../../Utitlity/routes/routes";
import secureLocalStorage from "react-secure-storage";

export enum Jwt_TOKEN {
  JWT_TOKEN = "JwtToken",
  TOKEN_EXPIRY = "TokenExpiry",
}

export enum REFRESH_TOKEN {
  REFRESH_TOKEN = "RefreshToken",
  REFRESH_TOKEN_EXPIRY = "RefreshTokenExpiry",
}

enum USER_DETAILS {
  NAME = "Name",
  SURNAME = "Surname",
  EMAIL = "Email",
  ROLE = "Role",
  USER_GUID = "UserGuid"
}

export type LogIn = {
  email: string;
  password: string;
};

let removeTokenDetails = () => {
  secureLocalStorage.removeItem(Jwt_TOKEN.JWT_TOKEN);
  localStorage.removeItem(Jwt_TOKEN.TOKEN_EXPIRY);
};

let removeUserDetails = () => {
  localStorage.removeItem(USER_DETAILS.NAME);
  localStorage.removeItem(USER_DETAILS.EMAIL);
  localStorage.removeItem(USER_DETAILS.SURNAME);
  localStorage.removeItem(USER_DETAILS.ROLE);
  localStorage.removeItem(USER_DETAILS.USER_GUID);
};

let removeRefreshTokenDetails = () => {
  secureLocalStorage.removeItem(REFRESH_TOKEN.REFRESH_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN.REFRESH_TOKEN_EXPIRY);
};

let setTokenDetails = (token: string, tokenExpiry: string) => {
  secureLocalStorage.setItem(Jwt_TOKEN.JWT_TOKEN, token);

  localStorage.setItem(Jwt_TOKEN.TOKEN_EXPIRY, tokenExpiry);
};

let setRefreshTokenDetails = (
  refreshToken: string,
  refreshTokenExpiry: string
) => {
  secureLocalStorage.setItem(REFRESH_TOKEN.REFRESH_TOKEN, refreshToken);

  localStorage.setItem(REFRESH_TOKEN.REFRESH_TOKEN_EXPIRY, refreshTokenExpiry);
};

let setUserDetails = (name: string, surname: string, email: string, role: string, userGuid: string) => {
  localStorage.setItem(USER_DETAILS.NAME, name);
  localStorage.setItem(USER_DETAILS.SURNAME, surname);
  localStorage.setItem(USER_DETAILS.EMAIL, email);
  localStorage.setItem(USER_DETAILS.ROLE, role);
  localStorage.setItem(USER_DETAILS.USER_GUID, userGuid);
};

export interface SignInResponse {
  gGuid: string;
  name: string;
  surname: string;
  role: string;
  email: string;
  token: string;
  refreshToken: string;
  expiresAt: string;
  jwtExpiresAt: string;
  refreshTokenExpiresAt: string;
  hasCompletedRegistrationProcess: boolean;
}

export interface RefreshTokenResponse {
  token: string;
  tokenExpiresAt: string;
  refreshToken: string;
  expireAt: string;
}

interface ApiSignInResponse {
  response: SignInResponse;
  statusCode: number;
  requestMadeAt: string;
  error: string;
}

interface ApiRefreshTokenResponse {
  response: RefreshTokenResponse;
  statusCode: number;
  requestMadeAt: string;
}

const signIn = async (loginData: LogIn): Promise<ApiSignInResponse | null> => {
  try {
    const formData = new FormData();
    formData.append("email", loginData.email);
    formData.append("password", loginData.password);

    const response = await fetch(AUTHENTICATION.POST.SIGN_IN, {
      method: "POST",
      body: formData,
    });

    const data: ApiSignInResponse = await response.json();

    return data;
  } catch (error) {
    return null;
  }
};

const refreshTokenAsync = async (
  refreshToken: string | undefined
): Promise<ApiRefreshTokenResponse | null> => {
  try {
    const formData = new FormData();

    formData.append("token", refreshToken!);

    const response = await fetch(AUTHENTICATION.POST.REFRESH_TOKEN, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: ApiRefreshTokenResponse = await response.json();

    return data;
  } catch (error) {
    return null;
  }
};

class Auth {
  LogIn = async (loginData: LogIn): Promise<ApiSignInResponse | undefined> => {
    // Remove any current data
    removeTokenDetails();
    removeRefreshTokenDetails();

    let returnData: ApiSignInResponse | undefined = undefined;

    await signIn(loginData).then((data) => {
      if (data) {
        if (data.statusCode === 200) {
          if (data.response) {
            setTokenDetails(data.response.token, data.response.jwtExpiresAt);
            setRefreshTokenDetails(
              data.response.refreshToken,
              data.response.refreshTokenExpiresAt
            );
            setUserDetails(
              data.response.name,
              data.response.surname,
              data.response.email,
              data.response.role,
              data.response.gGuid
            );

            localStorage?.setItem("isAuthenticated", JSON.stringify(true));
          } else {
            localStorage?.setItem("isAuthenticated", JSON.stringify(false));
          }
        } else {
          localStorage?.setItem("isAuthenticated", JSON.stringify(false));
        }

        returnData = data;
      }
    });

    return returnData;
  };

  RefreshToken = async (refreshToken: string): Promise<boolean> => {
    // Remove any current data
    removeTokenDetails();
    removeRefreshTokenDetails();

    let isSuccess: boolean = false;

    try {
      const data = await refreshTokenAsync(refreshToken); // Await the promise

      if (data) {
        if (data.statusCode === 200) {
          isSuccess = data.statusCode === 200 ? true : false;
          setTokenDetails(data.response.token, data.response.tokenExpiresAt);
          setRefreshTokenDetails(
            data.response.refreshToken,
            data.response.expireAt
          );
        }
      } else {
        removeRefreshTokenDetails();
        removeTokenDetails();
        removeUserDetails();

        return false;
      }
    } catch (error) {
      removeRefreshTokenDetails();
      removeTokenDetails();
      removeUserDetails();

      return false;
    }

    if (isSuccess) {
      return isSuccess;
    }

    return false;
  };
}

export default Auth;
