import { FC, useEffect, useMemo, useState } from "react";
import './Auth.css';
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { GetTripToJoin, JoinSelectedTrip } from "../../Utitlity/trips";
import { useNetworkStatus } from "../../Context/NetworkStatusContext";
import { ASSETS_URL } from "../../Utitlity/routes/routes";

interface ITripToJoin {
    Guid: string,
    TripName: string,
    TripStartDate: Date | null,
    TripEndDate: Date | null,
    ImageURL: string,
}

const JoinTrip: FC = (): JSX.Element => {
    const [tripToJoin, setTripToJoin] = useState<ITripToJoin>();
    const [errorMessage, setErrorMessage] = useState<string | null>();

    const { isOnline } = useNetworkStatus();

    const navigate = useNavigate();

    const findTrip = async () => {
        if (isOnline) {
            let data = await GetTripToJoin();

            if (data.response) {
                if (data.response.error) {
                    setErrorMessage("Unable to find trip.");
                } else {
                    let newTrip : ITripToJoin = {
                        Guid: data.response.tripGuid,
                        TripName: data.response.tripName,
                        TripStartDate: data.response.tripStartDate ? new Date(data.response.tripStartDate) : null,
                        TripEndDate: data.response.tripEndDate ? new Date(data.response.tripEndDate) : null,
                        ImageURL: ASSETS_URL + '/' + data.response.imagePath + '/' + data.response.imageName,
                    }

                    setTripToJoin(newTrip);
                }
            } else {
                setErrorMessage("Unable to find trip.");
            }
        }
    }

    const joinTrip = async () => {
        let result: any = await JoinSelectedTrip();

        if (result.statusCode === 200) {
            localStorage?.setItem("TripToJoin", '');
            navigate('/home');
        } else if (result.error) {
            setErrorMessage(result.error);
        } else {
            setErrorMessage("Unable to join trip.");
        }
    }

    useMemo(() => {
        findTrip();
    }, []);

    return (
        <div>
            <Header />

            <div className="py-4 px-6 relative">
                <Link to={'/home'} className="px-6 absolute top-0 bottom-0 left-0 flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                </Link>
                <h1 className="font-palatino uppercase text-3xl text-center">Join Trip?</h1>
            </div>

            <div className="px-6 py-4">
                <img className="bg-info w-full aspect-square object-cover" src={tripToJoin?.ImageURL} />
                <div className="py-2 px-4 bg-info">
                    <div className="font-gorditaBold">{tripToJoin?.TripName}</div>
                    <div className="pt-4 flex justify-between items-center">
                        <div className="text-primary flex items-center gap-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                            </svg>
                            {tripToJoin?.TripStartDate && tripToJoin.TripEndDate ? (
                                <div className="text-sm">
                                    {tripToJoin?.TripStartDate.toLocaleDateString()} - {tripToJoin?.TripEndDate.toLocaleDateString()}
                                </div>
                            ) : ''}
                        </div>
                    </div>
                </div>
            </div>

            <div className="px-6 my-6 flex flex-col justify-center items-center gap-4">
                <button onClick={() => joinTrip()} className="btn btn-block border-primary bg-transparent rounded-none hover:bg-primary hover:text-white">Join</button>
                <Link to={"/home"} className="btn btn-block border-accent text-accent bg-transparent rounded-none hover:bg-accent hover:text-white">Cancel</Link>
            </div>

            {errorMessage ? (
                <div className="px-6 py-4 bg-warning text-white text-center font-gorditaRegular">{errorMessage}</div>
            ) : ''}
        </div>
    );
}

export default JoinTrip;