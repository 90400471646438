const API_BASE_URL: string = "https://qa-api.saluteafrica.co.za/api/v1";
//const API_BASE_URL: string = "https://localhost:7007/api/v1";

const GOOGLE_API_KEY: string = "AIzaSyAl8YBPQrXay-AxME80l8HfBqIu_HcEfuE";

const WEATHERAPI_API_BASE_URL: string = "https://api.weatherapi.com/v1/"
const WEATHERAPI_API_KEY: string = "f0a0bb649633453ab6c113158240210";

const TRIPS_BASE_ROUTE: string = API_BASE_URL + "/trips"
export const TRIP = {
    GET: {
        GET_TRIP: TRIPS_BASE_ROUTE + "/get_trip",
        JOIN_TRIP: TRIPS_BASE_ROUTE + "/join_trip"
    },
    POST: {
        JOIN_TRIP: TRIPS_BASE_ROUTE + "/join_trip"
    }
};

const AUTHENTICATION_BASE_ROUTE: string = API_BASE_URL + "/account"

export const AUTHENTICATION = {
    POST : {
        SIGN_IN: AUTHENTICATION_BASE_ROUTE + "/sign_in",
        SIGN_UP: AUTHENTICATION_BASE_ROUTE + "/sign_up",
        REFRESH_TOKEN: AUTHENTICATION_BASE_ROUTE + "/refresh_token"
    }
}

export const ASSETS_URL: string = "https://qa-api.saluteafrica.co.za";
//export const ASSETS_URL: string = "https://localhost:7007";

export const GOOGLE_API = {
    MAPS_API_KEY: GOOGLE_API_KEY,
};

export const WEATHER_API = {
    GET_TODAYS_WEATHER_FORECAST: WEATHERAPI_API_BASE_URL + '/forecast.json?key=' + WEATHERAPI_API_KEY
}